import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentUser0, green0, selectedNumber0 } from '../../helpers/states';
import { get, post, abortRequest } from '../../helpers/apiHandler';
import { Store } from 'react-notifications-component';
import { MdPhoneInTalk } from 'react-icons/md';
import { ThsFilter } from '../../helpers/info';
import SelectCountryNum from '../../Components/handle_select_one/SelectCountryNum';
import { MdCallMissedOutgoing } from "react-icons/md";


function AutoDailer() {
    const [selectedNumber, setSelectedNumber] = useRecoilState(selectedNumber0)
    const [handleCount, setHandleCount] = useState({now_ring: 0, not_answer: 0})
    const Navigate = useNavigate()
    const [green, setGreen] = useRecoilState(green0)
    // 0: לא מחייג, לא בשיחה    // green  // start
    // 1: כן מחייג, לא בשיחה    // red    // stop @
    // 2: מפסיק לחייג, לא בשיחה // gray   // stoping @  // disable
    const state = useRef(0);
    const callingTimer = useRef([]);
    const activeApis = useRef([]);

    async function clearIntervals() {
        for (const timer of callingTimer.current) {
            clearInterval(timer);
        }
        callingTimer.current = [];
    }

    async function addCallingThread(time) {
        if (state.current !== 1) return;
        const timer = setTimeout(() => {
            startCalling();
            const timerIndex = callingTimer.current.indexOf(timer);
            if (timerIndex !== -1) {
                callingTimer.current.splice(timerIndex, 1);
            }
        }, time);
        callingTimer.current.push(timer);
    }

    async function cancelActiveApis() {
        abortRequest("/autodial");
        activeApis.current = [];
    }

    async function startCalling() {
        if (state.current !== 1) return;
        const leadsFilter = JSON.parse(localStorage.getItem('leadsFilter'));
        setHandleCount(prev => {
            return {
                ...prev,
                now_ring: prev['now_ring'] + 1
            }
        })
        const api = get('/autodial', { filter: leadsFilter.filter, country: selectedNumber?.value}).then((res) => {
            if (res.cancel) {
                state.current = 0;
                setGreen(0);
                return;
            }
            const apiIndex = activeApis.current.indexOf(api);
            if (apiIndex !== -1) {
                activeApis.current.splice(apiIndex, 1);
            }
            if (res.data.answer && res.data.connected) {
                console.log('he answer!!', res.data)
                setHandleCount({
                        now_answer: 0,
                        now_ring: 0
                    })
                state.current = 0;
                setGreen(0);
                // Cancel all active API calls
                // cancelActiveApis();
                Navigate(`/auto/${res.data.lead.id}`);
            } else {
                setHandleCount(prev => {
                    return {
                        not_answer: prev['not_answer'] + 1,
                        now_ring: prev['now_ring'] - 1
                    }
                })
                // console.log("State", state.current);
                if (res.data.answer || !res.ok) {
                    if (!res.ok && state.current === 1) {
                        Store.addNotification({
                            message: res.data.message || `something went wrong`,
                            type: "danger",
                            insert: "bottom",
                            container: "bottom-right",
                            dismiss: {
                                duration: 5000,
                            }
                        });
                    }
                    if (activeApis.current.length) {
                        clearIntervals();
                        state.current = 2;
                        setGreen(2);
                    } else {
                        state.current = 0;
                        setGreen(0);
                    }                    
                } else {
                    if (activeApis.current.length === 0 && state.current === 2) {
                        state.current = 0;
                        setGreen(0);
                    } else if (state.current === 1) {
                        addCallingThread(5000);
                    }
                }
            }
        });
        activeApis.current.push(api);
    }

    useEffect(() => {
        const handleGreenChange = async () => {
            state.current = green;
            if (green === 0) {
                await clearIntervals();
            } else if (green === 1) {
                for (var i = 0; i < 6; i ++)
                    await addCallingThread(5000 * i);
            } else if (green === 2) {
                await clearIntervals();
                if (activeApis.current.length === 0) {
                    state.current = 0;
                    setGreen(0);
                }
            }
        };
        handleGreenChange();
    }, [green]);




    //////// FILTER /////
    const [filters, setFilters] = useState([])
    useEffect(() => {
        if (localStorage.getItem('leadsFilter') === null) {
            Navigate('/leads')
        } else {
            setFilters(JSON.parse(JSON.parse(localStorage.getItem('leadsFilter')).filter))
        }
    }, [])

    

    return (
        <div className="flex flex-col h-[calc(100vh-32px)] min-h-[318px]"> {/* main-height: 350-32 */}
            <div className='flex flex-row space-x-4'>
                {
                    Object.keys(filters).map((filter, f) => (
                        (filters[filter].length > 0) && (
                            <div key={f} className='w-[160px] flex flex-col space-y-2'>
                                <span className='bg-blue-400 py-1 px-2 text-white font-semibold rounded-md'>{ThsFilter.find(item => item.key === filter)?.display}</span>
                                {
                                    filter !== 'favorite' && filters[filter].map((item, i) => (
                                        <div key={i} className='bg-blue-200 py-1 px-2 text-gray-800 rounded-md flex flex-row items-center space-x-1'>
                                            {
                                                item.operator === undefined ? (
                                                    <span>{item.label}</span>
                                                ) : (
                                                    <>
                                                        <span className='text-sm font-semibold'>{item.operator}: </span>
                                                        <span>
                                                            {item.value}
                                                        </span>
                                                    </>
                                                )
                                            }

                                        </div>
                                    ))
                                }
                            </div>
                        )
                    ))
                }
            </div>
            <div className='my-4 flex justify-center space-x-5'>
                <div className='text-[32px] font-semibold text-gray-600 flex flex-row items-center card py-0 space-x-2'>
                    <span className='text-green-600'>{handleCount['now_ring']}</span>
                    <MdCallMissedOutgoing className='text-[35px] relative top-[3px]' />
                    <span className='text-red-600'>{handleCount['not_answer']}</span>
                </div>
            </div>
            <div className='flex-grow flex items-center justify-center'>
                <MainBut green={green} setGreen={setGreen} selectedNumber={selectedNumber} setSelectedNumber={setSelectedNumber} />
            </div>
        </div>

    )

}

export default AutoDailer;


const MainBut = ({ green, setGreen, selectedNumber, setSelectedNumber }) => {

    const handleBut = async () => {
        setGreen(prev => (
            prev === 0 ? 1 :
                prev === 1 ? 2 :
                    prev
        ))
    }
    

    return (
        <div className='flex flex-row items-center space-x-4'>
            <SelectCountryNum selectedNumber={selectedNumber} setSelectedNumber={setSelectedNumber} />
            <button
                onClick={handleBut}
                className={`
                    but but-basic-color text-3xl py-3 !px-5 !h-auto
                    ${green === 0 ? 'bg-green-600 hover:bg-green-700 active:bg-green-800' :
                        green === 1 ? 'bg-red-600 hover:bg-red-700 active:bg-red-800' :
                            green === 2 ? 'bg-gray-400 cursor-default' :
                                ''
                    }
                `}
                disabled={selectedNumber === undefined}
            >
                <div className="flex flex-row items-center space-x-2">
                    {
                        green === 0 ? <MdPhoneInTalk className='' /> :
                            (green === 1 || green === 2) && <div className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status"></div>
                    }
                    <span>
                        {
                            green === 0 ? 'START' :
                                green === 1 ? 'STOP' :
                                    green === 2 ? 'STOPING' :
                                        ''
                        }
                    </span>
                </div>
            </button>
        </div>
    )
}



// 3: לא מחייג, כן בשיחה    // YELLOW // on call    // disable