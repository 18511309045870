import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { currentUser0, selected0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import { updateUser } from '../../helpers/funcs';
import { get } from '../../helpers/apiHandler';
import { Store } from 'react-notifications-component';

const CustomOption = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className="flex flex-row items-center space-x-1">
          <img
            src={`/flags/${data.iso.toLowerCase()}.png`}
            alt={data.label}
            className="w-5 h-5 object-cover rounded-full"
          />
          <span>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
};

const CustomValue = ({ data }) => {
    return (
      <div className="flex flex-row items-center">
        {/* <img
          src={`/flags/${data.iso.toLowerCase()}.png`}
          alt={data.label}
          className="w-6 mr-2"
        /> */}
        {data.label}
      </div>
    );
};

const SelectNumber = ({top}) => {
    ///////////////////// NUMBERS ///////////////////
    const [dataNumbers, setDataNumbers] = useState([])
    const getNumbers = async () => {
        const res = await get('/numbers')
        if (res.ok) {
            setDataNumbers(res.data)
        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
    }
    useEffect(() => {
        getNumbers()
    }, [])


    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [selected, setSelected] = useRecoilState(selected0);

    useEffect(() => {
        if (dataNumbers.length > 0) {
            var found = dataNumbers.find(item => item.number === currentUser.callerid)
            if (found !== undefined) {
                setSelected({
                    value: found.number,
                    label: found.number,
                    iso: found.country
                })
            } else {
                setSelected({
                    value: dataNumbers[0].number,
                    label: dataNumbers[0].number,
                    iso: dataNumbers[0].country
                })
            }
        }
    }, [dataNumbers])

    const handleListChange = (selectedOption) => {
        setSelected(selectedOption);
    };

    useEffect(() => {
        if (selected.value !== '' && selected.value !== currentUser.callerid) {
            setCurrentUser(prev => {
                return {
                    ...prev,
                    callerid: selected.value
                }
            })
            updateUser(currentUser['id'], {callerid: selected.value})
        }
    }, [selected])

    return (
        <div className="text-gray-800 text-[14px] flex flex-row items-center space-x-2">
            <Select
                value={selected}
                onChange={handleListChange}
                closeMenuOnSelect={true}
                name="sel_num"
                menuPlacement={`${top ? 'top' : 'bottom'}`}
                options={dataNumbers.map((item) => ({
                    value: item.number,
                    label: item.number,
                    iso: item.country
                }))}
                components={{ Option: CustomOption}} // SingleValue: CustomValue 
                className="basic-single z-30 !w-[180px]"
                classNamePrefix="select"
            />
            {
                selected.iso !== 'aa' && (
                    <img
                        src={`/flags/${selected.iso?.toLowerCase()}.png`}
                        alt={dataNumbers.label}
                        className="w-6 mr-2"
                    />
                )
            }
        </div>
    );
};

export default SelectNumber;
