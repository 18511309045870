import { Link, useNavigate } from "react-router-dom";
import isoToCountry from "../../docs/isoToCountry";
import IsoToLang from "../../docs/IsoToLang";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { useEffect, useState } from 'react';
import SelectStatusOne from '../handle_select_one/SelectStatusOne';
import SelectLangOne from '../handle_select_one/SelectLangOne';
import { updateLead, updateLevel } from '../../helpers/funcs';
import SelectLevelOne from '../handle_select_one/SelectLevelOne';
import { useRecoilState } from 'recoil';
import { ring0 } from '../../helpers/states';
import { GoDotFill } from "react-icons/go";
import { bankColor } from "../../helpers/info"
import { RiDeleteBin6Line } from 'react-icons/ri';
import { get, put } from '../../helpers/apiHandler';
import accessLevel from '../../docs/accessLevel';
import Ring from '../Ring';



const Tr = ({line, titles, fetchURL, tableType, setData, setShowConfirm, setTranId}) => {
    const [isFav, setIsFav] = useState(line?.favorite);
    const [selectedStatus, setSelectedStatus] = useState(line['status'])
    const [selectedLang, setSelectedLang] = useState(line['lang'] || '')
    // const [selectedLevel, setSelectedLevel] = useState(line['access_level'] || '')

    const getRoute = (text) => {
        if (typeof text === typeof undefined) return 'abcdedg'
        if (text.includes('lead')) return '/leads/'
        if (text.includes('user')) return '/users/'
        if (text.includes('office')) return '/offices/'
        return '//'
    }

    useEffect(() => {
        if (selectedLang !== '' && selectedLang !== line['lang']) {
            var data = { lang: selectedLang}
            updateLead(line['id'], data)
            setData(prev => {
                return {
                    ...prev,
                    data: prev.data.map(lead => lead.id === line['id'] ? {...lead, ...data} : lead)
                }
            })
        }
    }, [selectedLang])

    // useEffect(() => {
    //     if (selectedLevel !== '' && selectedLevel !== line['access_level']) {
    //         updateLevel(line['id'], selectedLevel)
    //     }
    // }, [selectedLevel])


    const [theStatus, setTheStatus] = useState(bankColor[line['status']])
    //// phone //// 
    const [handleRing, setHandleRing] = useRecoilState(ring0)

    ///// STATUS /////
    useEffect(() => {
        if (line['status'] !== selectedStatus) {
            var data = { status: selectedStatus}
            updateLead(line['id'], data)
            setData(prev => {
                return {
                    ...prev,
                    data: prev.data.map(lead => lead.id === line['id'] ? {...lead, ...data} : lead)
                }
            })
        }
    }, [selectedStatus])

    useEffect(() => {
        if (theStatus !== bankColor[line['status']]) {
            setTheStatus(bankColor[line['status']])
        }
    }, [line['status']])


    ////////////// FAVORITE //////////////
    const handleIsFav = async () => {
        setIsFav((prev) => !prev)
    }

    useEffect(() => {
        if (line['favorite'] !== isFav) {
            var data = { favorite: isFav}
            updateLead(line['id'], data)
            setData(prev => {
                return {
                    ...prev,
                    data: prev.data.map(lead => lead.id === line['id'] ? {...lead, ...data} : lead)
                }
            })
        }
    }, [isFav])

    
    return (
        <tr className={`${tableType === 0 ? 'border-l-[4px]' : ''} ${handleRing?.last === line.id && tableType === 0 ? ' border-[#536294] ' : ''}`} style={{backgroundColor: theStatus}}>
            
            {
                tableType == 0 && (
                    <td className="!pr-0">
                        <div className="cursor-pointer" onClick={handleIsFav}>
                            {
                                isFav ? (
                                    <FaStar className="text-blue-500" />
                                    ) : (
                                    <CiStar className="text-blue-500" />
                                )
                            }
                        </div>
                    </td>
                )
                
                // : tableType == 1 && (
                //     <td className='!pr-0'>
                //         <input type="checkbox" />
                //     </td>
                // ) 
                
                // (
                //     <td className='!pr-0'>
                //         <input type="checkbox" />
                //     </td>
                // )
            }
            {
                titles.map((title, t) => (
                    
                    (title.key == 'first_name' || title.key == 'name') ? (
                        <td key={t} className="font-medium sticky left-0 hover:underline z-10" style={{backgroundColor: theStatus}}>
                            <Link to={`${fetchURL}/${line['id']}`}>{line[title.key]}</Link>
                        </td>
                    ) : 
                    
                    title.key == 'phone' ? (
                        <td key={t}>
                            <div className="flex items-center justify-center">
                                <Ring uid={line.id} />
                            </div>
                        </td>
                    ) :

                    title.key == 'country' ? (
                        <td key={t}>{isoToCountry[line[title.key]]}</td>
                    ) :

                    title.key == 'lang' ? (
                        <td key={t} className='!overflow-visible'>
                            <SelectLangOne selected={selectedLang} setSelected={setSelectedLang} />
                        </td>
                    ) :


                    title.key == 'status' ? (
                        <td key={t}>
                            <SelectStatusOne selected={selectedStatus} setSelected={setSelectedStatus} />
                        </td>
                    ) :

                    title.key == 'access_level' ? (
                        <td key={t}>
                            {/* <SelectLevelOne selected={selectedLevel} setSelected={setSelectedLevel} /> */}
                            <span>{accessLevel.find(item => Number(item.value) === line['access_level']).label}</span>
                        </td>
                    ) :

                    title.key == 'online' ? (
                        <td key={t} className='w-[20px]'>
                            <div className='flex items-center justify-center'>
                                <GoDotFill className='text-red-500 text-[22px]' />
                            </div>
                        </td>
                    ) :


                    title.key == 'delete' ? (
                        <td key={t} className='w-[20px]'>
                            <button className='rounded-full but-basic-emp-emp w-6 h-6 flex items-center justify-center relative top-[1px] hoverEffect2' onClick={() => {setShowConfirm(true); setTranId(line['id'])}}><RiDeleteBin6Line className="text-[16px]" /></button>
                        </td>
                    ) :


                    title.key == 'attachment' ? (
                        tableType == 2 ? (
                            <td key={t}>
                                <Link className='font-semibold hover:underline' to={typeof line['user'] !== typeof undefined ? `/users/${line['user_id']}` : getRoute(line['action']) + line['subject_id']}>
                                    {line['name']}
                                </Link>
                            </td>
                        ) : 
                        tableType == 3 && (
                            <td key={t}>
                                {
                                    typeof line['lead'] !== typeof undefined ? (
                                        <Link className='font-semibold hover:underline' to={`/leads/${line['lead_id']}`}>{line['lead']['first_name']}</Link>
                                        // 'lead' + line['lead_id']
                                    ) :

                                    typeof line['user'] !== typeof undefined && (
                                        <Link className='font-semibold hover:underline' to={`/users/${line['user_id']}`}>{line['user']['name']}</Link>
                                        // 'user' + line['user_id']
                                    )
                                }
                            </td>
                        )
                    ) :


                    <td key={t}>{line[title.key]}</td>
                    
                ))
            }
    </tr>
    )
}

export default Tr