import { RiDashboard2Fill, RiLogoutBoxRLine } from 'react-icons/ri';
import { MdPermContactCalendar } from 'react-icons/md';
import { FaUserShield } from 'react-icons/fa';
import { BiSolidBriefcase } from 'react-icons/bi';
import { AiFillSetting } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IoIosSettings } from "react-icons/io";
import { useRecoilState } from 'recoil';
import { currentUser0, leadsTable0, pos0 } from '../helpers/states';
import { logout_func } from '../helpers/funcs';
import { PiFilesFill } from "react-icons/pi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { MdOutlineContactPhone } from "react-icons/md";
import SelectNumber from '../Components/handle_select_one/SelectNumber';
import { get } from '../helpers/apiHandler';
import { Store } from 'react-notifications-component';


const Menu = () => {
    const Location = useLocation();
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const randomId = 'sett_menu'
    const randomId2 = 'sett_menu2'
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    useEffect(() => {
        if (show) {
            const handleBodyClick = (e) => {
                var element = e.target
                var isOut = true;
                while (true) {
                    if (element.id == randomId || element.id == `but-${randomId}`) {
                        isOut = false;
                        break;
                    };
                    element = element.parentElement
                    if (element == null) break;
                }
                if (isOut) setShow(false);
            };
                document.body.addEventListener('click', handleBodyClick);
            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }
    }, [show])

    useEffect(() => {
        if (show2) {
            const handleBodyClick = (e) => {
                var element = e.target
                var isOut = true;
                while (true) {
                    if (element.id == randomId2 || element.id == `but-${randomId2}`) {
                        isOut = false;
                        break;
                    };
                    element = element.parentElement
                    if (element == null) break;
                }
                if (isOut) setShow2(false);
            };
                document.body.addEventListener('click', handleBodyClick);
            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }
    }, [show2])
 
    const handleActiveCall = async  () => {
        const res = await get('/activecall')
        if (res.ok) {
            if (res.data?.lead !== null) {
                navigate(`/leads/${res.data.lead}`)
            } else {
                Store.addNotification({
                    message: "There is no active call",
                    type: 'danger',
                    insert: "bottom",
                    container: "bottom-right",
                    dismiss: {
                      duration: 5000,
                    }
                });
            }
        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: 'danger',
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                    duration: 5000,
                }
            });
        }
    }

    return (
        <div style={{backgroundImage: "url('/snow.jpg')"}} className="main-height sticky top-0 text-[#98a4cf] z-30">
            <div className='w-[70px] flex flex-col justify-between main-height bg-[#405189] bg-opacity-90'>

                <div className='flex flex-col'>
                    <div className={`w-full h-[70px] flex items-center justify-center hover:text-white`}>
                        {/* <Box link='/' icon={ */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M17.5 6.5C18.4459 6.5 19.3152 6.17164 20 5.62269V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0H14.3773C13.8284 0.684799 13.5 1.55405 13.5 2.5C13.5 4.70914 15.2909 6.5 17.5 6.5ZM6.75 7C6.75 6.30964 7.30964 5.75 8 5.75C8.69036 5.75 9.25 6.30964 9.25 7V13C9.25 14.5188 10.4812 15.75 12 15.75C13.5188 15.75 14.75 14.5188 14.75 13V12C14.75 11.3096 15.3096 10.75 16 10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H16C14.4812 9.25 13.25 10.4812 13.25 12V13C13.25 13.6904 12.6904 14.25 12 14.25C11.3096 14.25 10.75 13.6904 10.75 13V7C10.75 5.48122 9.51878 4.25 8 4.25C6.48122 4.25 5.25 5.48122 5.25 7V8C5.25 8.69036 4.69036 9.25 4 9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H4C5.51878 10.75 6.75 9.51878 6.75 8V7ZM17.5 5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0C16.1193 0 15 1.11929 15 2.5C15 3.88071 16.1193 5 17.5 5Z" 
                                fill={Location.pathname !== '/' ? "#98a4cf" : '#fff'} /></svg>
                        {/* }/> */}
                    </div>
                </div>
        

                <div className='flex flex-col items-center w-full'>
                    <Box link='/files' icon={<PiFilesFill className='text-[30px]' />} />
                    <Box link='/leads' icon={<MdPermContactCalendar className='text-[30px]' />} />
                    <Box link='/users' icon={<FaUserShield className='text-[30px]' />} />
                    <Box link='/offices' icon={<BiSolidBriefcase className='text-[30px]' />} />
                </div>

                
                <div className='flex flex-col'>
                    <div className='flex flex-col space-y-6'>
                        <div className='flex justify-center hover:text-white cursor-pointer' onClick={handleActiveCall}>
                            <TfiHeadphoneAlt className='text-[23px]' />
                        </div>
                        <div className='flex justify-center hover:text-white cursor-pointer' onClick={() => setShow2(prev => !prev)} id={`but-${randomId2}`}>
                            <MdOutlineContactPhone  className='text-[23px]' />
                        </div>
                    </div>
                    <div className='cursor-pointer' onClick={() => setShow(prev => !prev)} id={`but-${randomId}`}>
                        <div className={`w-full h-[70px] flex items-center justify-center hover:text-white`}>
                            <IoIosSettings className='text-[29px]' />
                        </div>
                    </div>
                </div>


                {
                    show2 && (
                        <div className='absolute bottom-[55px] left-[75px]' id={`${randomId2}`}>
                            <div className='card border w-[200px] p-2'>
                                <SelectNumber top={true} />
                            </div>
                        </div>
                    )
                }

                {
                    show && (
                        <div className='absolute bottom-[5px] left-[75px]' id={`${randomId}`}>
                            <div className='card border w-[150px] p-0 py-1'>
                                <ul className='text-[16px] text-gray-700 [&>li]:p-3 [&>li]:cursor-pointer font-semibold'>
                                    <li onClick={() => {setShow(false); navigate(`/users/${currentUser.id}`)}} className='hover:bg-gray-100 flex flex-row items-center space-x-3 active:bg-gray-200'>
                                        <CgProfile className='text-[20px]' />
                                        <span>
                                            Profile
                                        </span>
                                    </li>
                                    <li className='hover:bg-gray-100 flex flex-row items-center space-x-3 active:bg-gray-200' onClick={logout_func}>
                                        <RiLogoutBoxRLine className='text-[20px]' />
                                        <span>
                                            Logout
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}

const Box = ({icon, link, link0}) => {
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)
    const [pos, setPos] = useRecoilState(pos0)
    const Location = useLocation();
    return (
        // bg-[#39487b]
        <Link 
            onClick={() => {setLeadsTable({"leads": undefined,"users": undefined}); setPos(0);}} 
            to={link} className={`${Location.pathname.includes(link) || Location.pathname.includes(link0) ? 'text-white' : ''} w-full h-[70px] flex items-center justify-center hover:text-white`}>
                {icon}
        </Link>
    )
}

export default Menu