import { useRecoilState } from "recoil";
import { currentUser0, ring0 } from "../helpers/states";
import { Store } from 'react-notifications-component';
import { get } from "../helpers/apiHandler";
import { BsTelephoneFill } from 'react-icons/bs';
import { BsFillTelephoneInboundFill } from "react-icons/bs";


const Ring = ({uid}) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [handleRing, setHandleRing] = useRecoilState(ring0)
    const makeCall = async () => {
        Store.addNotification({
            message: "Ringing to lead...",
            type: 'success',
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 15000,
            }
        });
        setHandleRing(prev => {
            return {
                ...prev,
                isCalling: true,
                last: uid
            }
        })
        const res = await get(`/lead/${uid}/call`, {callerid: currentUser.callerid})
        if (res.ok) {
            var message = "No answer"
            var msg_type = "danger";
            if (res.data.connected) {
                message = "Call established successfully";
                msg_type = "success";
            } else if (res.data.answer) {
                message = "Call failed to connect";
            }
            Store.addNotification({
                message: message,
                type: msg_type,
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            
        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setHandleRing(prev => {
            return {
                ...prev,
                isCalling: false,
            }
        })
    }

    return (
        <button
            disabled={handleRing.isCalling} 
            onClick={() => makeCall()} 
            className={`flex items-center justify-center w-7 h-7  border rounded-full 
                ${handleRing.isCalling 
                    ? uid === handleRing?.last // disabled
                        ? 'bg-[#405189] text-white border-[#405189]  animate-pulse' // call & last
                        : 'bg-white text-gray-400 border-gray-400' // call & no-last
                    : uid === handleRing?.last
                        ? 'text-[#405189] border-[#405189] bg-[#405189] text-white active:bg-[#39487b]' // no-call & last
                        : 'text-[#405189] border-[#405189] bg-white hover:bg-[#405189] hover:text-white active:bg-[#39487b]' // no-call & no-last
                }
            `}>
            {
                handleRing.isCalling && uid === handleRing?.last ? (
                    //  animate-bounce animate-pulse animate-spin
                    <BsFillTelephoneInboundFill className="text-[14px]" />
                ) : (
                    <BsTelephoneFill className="text-[14px]" />
                )
            }
        </button>
    )
}


export default Ring