import { useParams } from 'react-router-dom';
import TableLayout from '../../../Components/handle_table/TableLayout';
import { useEffect, useState } from 'react';
import Notes from './Notes';
import Skeleton from 'react-loading-skeleton'
import SelectLangOne from '../../../Components/handle_select_one/SelectLangOne';
import { updateLead } from '../../../helpers/funcs';
import { get, post, put } from '../../../helpers/apiHandler';
import { Store } from 'react-notifications-component';
import isoToCountry from '../../../docs/isoToCountry';
import SelectStatusOne from '../../../Components/handle_select_one/SelectStatusOne';
import { currentUser0, forceUpdateTrans0, ring0, leadsTable0 } from '../../../helpers/states';
import { useRecoilState } from 'recoil';
import AddTran from './AddTran';
import { CiMail, CiStar } from "react-icons/ci";
import { BsTelephoneFill } from 'react-icons/bs';
import { FaStar } from 'react-icons/fa';

const LeadPage = () => {
    const [lead, setLead] = useState()
    let { leadId } = useParams();

    const objBaseLogs = {"description":[],"created_at":[]}
    const objBaseTrans = {"amount":[],"date":[]}

    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const [forceUpdateTrans, setForceUpdateTran] = useRecoilState(forceUpdateTrans0);
    const [forceUpdateLogs, setForceUpdateLogs] = useState(100);


    useEffect(() => {
        setForceUpdateTran(prev => prev + 1);
        setForceUpdateLogs(prev => prev + 1);
    }, [leadId])


    const fetchLead = async () => {
        const result = await get(`/lead/${leadId}`)
        if (result.ok) {
            setLead({
                lang: result.data?.lang || 'en',
                ...result.data
            })
        } else {
            setLead({})
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
    }
    useEffect(() => {
        fetchLead()
    }, [])

    const getAllThsTrans = () => {
        if (currentUser.access_level >= 3) return allThsTrans;
        return allThsTrans.filter(item => item.key !== 'delete');
    }

    //////////////////////////////////// update ////////////
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)
    const update = (key, value) => {
        setLead(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
        updateLead(leadId, {[key]: value})

        if (leadsTable['leads'] !== undefined) {
            setLeadsTable(prev => {
                var indexLead = prev['leads']['data'].findIndex(item => item.id == leadId)
                var newData = [...prev['leads']['data']]
                newData[indexLead] = {
                    ...newData[indexLead],
                    [key]: value
                }
                return {
                    ...prev,
                    leads: {
                        ...prev['leads'],
                        data: [...newData]
                    }
                }
            })
        }
    }

    const [handleRing, setHandleRing] = useRecoilState(ring0)
    const makeCall = async () => {
        Store.addNotification({
            message: "Ringing to lead...",
            type: 'success',
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 15000,
            }
        });
        setHandleRing(prev => {
            return {
                ...prev,
                last: leadId,
                isCalling: true
            }
        })
        const res = await get(`/lead/${leadId}/call`, {callerid: currentUser.callerid})
        if (res.ok) {
            var message = "No answer"
            var msg_type = "danger";
            if (res.data.connected) {
                message = "Call established successfully";
                msg_type = "success";
            } else if (res.data.answer) {
                message = "Call failed to connect";
            }
            Store.addNotification({
                message: message,
                type: msg_type,
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            
        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setHandleRing(prev => {
            return {
                ...prev,
                isCalling: false,
            }
        })
    }
    

    return (
        <div className='grid grid-cols-5 gap-4'>

            <div className='card p-0 col-span-full lg:col-span-3'>
                <div className='grid grid-cols-3 lg:grid-cols-4 gap-0'>

                    <div className='col-span-full border-b p-4 flex flex-row justify-between'>
                        <div className='flex flex-row space-x-5 items-center'>
                            <div className='bg-violet-400 w-20 h-20 flex items-center justify-center rounded-full text-white text-[27px] font-semibold'>
                                {lead?.first_name.slice(0,1)}
                                {lead?.last_name.slice(0,1)}
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-row space-x-1.5 text-2xl font-semibold items-center'>
                                    {
                                        typeof lead === typeof undefined
                                        ? <Skeleton className='w-[150px]' />
                                        : <span>{lead?.first_name} {lead?.last_name}</span>
                                    }
                                    <div className="cursor-pointer text-[20px]" onClick={() => update('favorite', !lead?.favorite)}>
                                        {
                                            lead?.favorite || false ? (
                                                <FaStar className="text-blue-500" />
                                                ) : (
                                                <CiStar className="text-blue-500" />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-row space-x-1 items-center'>
                                    <CiMail className='text-xl' />
                                    {
                                        <span className='text-gray-600 relative bottom-[0.5px]'>
                                            {
                                                typeof lead === typeof undefined 
                                                ?   <Skeleton className='w-[70px]' />
                                                :   lead.email
                                            }
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row items-start space-x-2'>

                            <button disabled={handleRing.isCalling} onClick={makeCall} className={`rounded-full but-basic-emp-green disabled:bg-gray-400 disabled:border-gray-400 disabled:animate-pulse w-9 h-9 flex items-center justify-center`}>
                                <BsTelephoneFill className="text-[16px]" />
                            </button>
                        </div>
                    </div>
                    {
                        infos.map((info, i) => (
                            <div key={i} className={`border-b border-r p-2 flex flex-col  items-center  text-center space-y-1 ${info.key === 'lang' ? '' : 'overflow-hidden'}`}> 
                                <span className={`text-sm text-gray-700 ${info.imp ? 'bg-blue-200 px-2 rounded-md text-black' : ''}`}>{info.display}</span>
                                {
                                    info.key === 'lang' ? (
                                        typeof lead === typeof undefined 
                                        ?   <Skeleton className='w-[55px]' />
                                        :   <SelectLangOne selected={lead?.lang || 'en'} setSelected={(value) => update('lang', value)} />
                                    ) :
                                    info.key === 'status' ? (
                                        <span className='font-semibold px-1 rounded-md'>
                                            {
                                                typeof lead === typeof undefined 
                                                ? <Skeleton className='w-[55px]' /> 
                                                : <SelectStatusOne selected={lead?.status || 'New'} setSelected={(value) => update('status', value)} />
                                            }
                                        </span>
                                    ) :
                                        <span className='font-semibold'>
                                            {
                                                typeof lead === typeof undefined 
                                                ? <Skeleton className='w-[55px]' /> 
                                                : info.key === 'country' ? isoToCountry[lead[info.key]] : lead[info.key]
                                            }
                                        </span>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            <Notes leadId={leadId} />
            <div className='flex flex-col col-span-full lg:col-span-3 space-y-4'>
                <TableLayout key={forceUpdateTrans} objBase={objBaseTrans} allThs={getAllThsTrans()} fetchURL={`/lead/${leadId}/transactions`} tableType={3} title={'Transactions'} ButPop={AddTran} update_lead={update} />
                <TableLayout key={forceUpdateLogs} objBase={objBaseLogs} allThs={allThsLogs} fetchURL={`/lead/${leadId}/logs`} tableType={2} title={'Logs'} />
            </div>
        </div>
    )
}

export default LeadPage;



const allThsTrans = [
    // {key: 'id', display: 'id'},
    {key: 'amount', display: 'amount'},
    {key: 'attachment', display: 'attachment'},
    {key: 'date', display: 'date'},
    {key: 'delete', display: ''},
    // {key: 'created_at', display: 'created at'},
]
const allThsLogs = [
    // {key: 'id', display: 'id'},
    {key: 'description', display: 'description'},
    {key: 'attachment', display: 'attachment'},
    {key: 'created_at', display: 'date'},
]



var infos = [
    // {key: "id", display: 'id'}, ################
    // {key: "first_name", display: 'first name', imp: true}, ############
    // {key: "last_name", display: 'last name', imp: true},###############
    // {key: "email", display: 'email'},######################

    {key: "file_name", display: 'file name', imp: true},
    {key: "brand", display: 'brand', imp: true},
    {key: "lead_source", display: 'lead source', imp: true},
    {key: "status", display: 'status', imp: true},
    {key: "country", display: 'country', imp: true},
    {key: "lang", display: 'language', imp: true},
    {key: "city", display: 'city'},
    {key: "address", display: 'address'},
    {key: "net_deposit", display: 'net deposit'},
    {key: "last_time_deposit", display: 'last time deposit'},
    {key: "original_agent", display: 'original agent'},
    {key: "total_transaction_sum", display: 'total transaction'},
    {key: "birthday", display: 'birthday'},
    {key: "created_at", display: 'created at'},
    {key: "registration_time", display: 'registration time'},
    {key: "ftd_date", display: 'ftd date'},


    // {key: "zip", display: 'zip'},
    // {key: "calls_count", display: 'calls count'},
    // {key: "lead_score", display: 'lead score'},
]